import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Div100vh from 'react-div-100vh';
import SEO, { OGType } from '../components/head/SEO';
import Breadcrumbs from '../components/head/Breadcrumbs';
import { navbarLayout } from '../layouts/navbarLayout';
import {
  questionsActions,
  selectSearchChatQuestionCollection
} from '../redux/features/questions';
import Modal from '../components/common/Modal';
import SearchChatModalContent from '../components/search/SearchChatModalContent';
import WelcomeBackScreen from '../components/home/WelcomeBackScreen';
import { CmsContentPage } from '../integrations/contentful/types';
import ContentBlockList from '../components/content-blocks/ContentBlockList';
import { fetchContentPage } from '../integrations/contentful/queries';
import StartScreen from '../components/home/StartScreen';
import { baseXUnit, breakpoints } from '../styles/theme';
import { DEFAULT_NAVBAR_ANCHOR_OFFSET_IN_PIXELS } from '../components/navigation/DefaultNavbar';
import { TimPage } from './TimPage';
import { UserContext } from './_app';

interface HomePageProps {
  contentPage?: CmsContentPage;
}

const HomePage: TimPage<HomePageProps> = ({ contentPage }) => {
  const [showChat, setShowChat] = React.useState(false);
  const open = () => setShowChat(true);
  const close = () => setShowChat(false);
  const modalTitle = 'Tim';

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(questionsActions.makeSureSearchChatQuestionsLoaded());
  }, [dispatch]);
  const questionCollectionLoadable = useSelector(
    selectSearchChatQuestionCollection
  );
  const { user } = useContext(UserContext);
  const showWelcomeBackScreen = user.isChatFinished();

  if (!contentPage) {
    return null;
  }

  return (
    <div data-cy="home-page">
      <SEO
        title={contentPage.fields.seoTitle || contentPage.fields.title}
        canonicalPath="/"
        type={OGType.website}
        description={
          contentPage.fields.seoDescription ||
          contentPage.fields.leadParagraph ||
          ''
        }
      />
      <Breadcrumbs />
      <div className="tim-section">
        <Div100vh style={{ minHeight: '100rvh' }}>
          {showWelcomeBackScreen ? (
            <WelcomeBackScreen startChat={open} />
          ) : (
            <StartScreen startChat={open} />
          )}
        </Div100vh>
      </div>
      <div className="block-list-shadow" />
      <div className="block-list-wrapper">
        {contentPage.fields.blocks && (
          <ContentBlockList blocks={contentPage.fields.blocks} />
        )}
      </div>
      <Modal
        isOpen={showChat}
        onDismiss={close}
        ariaLabel={modalTitle}
        animateIn
        variant="right"
      >
        <SearchChatModalContent
          title={modalTitle}
          closeChat={close}
          questionCollectionLoadable={questionCollectionLoadable}
        />
      </Modal>
      {/*language=CSS*/}
      <style jsx>{`
        /* Move the section up so that the content fits into the first fold */
        .tim-section {
          margin-top: ${DEFAULT_NAVBAR_ANCHOR_OFFSET_IN_PIXELS}px;
        }
        .tim-section > :global(div) {
          padding-top: ${DEFAULT_NAVBAR_ANCHOR_OFFSET_IN_PIXELS * -1}px;
          display: flex;
          justify-content: center;
        }

        .block-list-shadow {
          background: #fff;
          box-shadow: 0 -2px 15px 0 rgba(0, 0, 0, 0.15);
          border-radius: ${baseXUnit(2)};
          margin-top: -${baseXUnit(2.5)};
          margin-bottom: -${baseXUnit(2.5)};
          height: ${baseXUnit(5)};
          position: relative;
          z-index: 0;
        }

        .block-list-wrapper {
          position: relative;
          z-index: 1;
          background: #fff;
          padding-top: ${baseXUnit(12)};
        }

        @media ${breakpoints.medium} {
          .block-list-shadow {
            display: none;
          }

          .tim-section > :global(div) {
            min-height: auto !important;
          }
        }
      `}</style>
    </div>
  );
};

HomePage.getLayout = (page) => navbarLayout(null, page);

HomePage.getInitialProps = async function () {
  const contentPage = await fetchContentPage('home');
  const httpErrorCode = !contentPage ? 404 : undefined;
  return { contentPage, httpErrorCode };
};

export default HomePage;
