import React from 'react';
import { t } from '../../utils/i18n';
import H from '../elements/H';
import Spacing from '../common/Spacing';
import Tim, { TimState } from '../tim/Tim';
import Container from '../grid/Container';
import ButtonText from '../elements/ButtonText';
import { TestableSpring } from '../module-overrides/react-spring';
import TypedText from '../common/TypedText';
import Button from '../elements/Button';
import { breakpoints, color, spacing } from '../../styles/theme';

interface StartScreenProps {
  startChat: () => void;
}

const StartScreen: React.FunctionComponent<StartScreenProps> = ({
  startChat
}) => {
  const [timState, setTimState] = React.useState<TimState>('reveal');
  const showText = timState !== 'reveal';
  const showButton = timState === 'idle';

  return (
    <div
      className="start-screen"
      data-cy="start-screen"
      data-testid="start-screen"
    >
      <Container>
        <div className="flex-wrapper">
          <div className="tim-and-welcome-text">
            <Spacing top={[6.5, 8, 8]} bottom={[6.5, 8, 8]}>
              <Tim
                animateIn
                state={timState}
                onReveal={() => {
                  // This is set based on how the animations look together
                  setTimeout(() => setTimState('talk'), 700);
                }}
                size={[3, 4, 4]}
              />
            </Spacing>
            <TypedText
              // This is set based on how the animations look together
              startDelay={700}
              onAnimationDone={() => setTimState('idle')}
              wait={!showText}
            >
              <Spacing bottom={[4, 3, 3]}>
                <H styling="h2" tag="div" data>
                  {t('Moi! 👋 Olen Tim.')}
                </H>
              </Spacing>
              <Spacing bottom={[4, 3, 3]}>
                <H styling="h2" tag="div" data>
                  <div className="tim-text-wrapper">
                    <div>{t('Minut on luotu')} </div>
                    <div>{t('rakentamaan')} </div>
                    <div>{t('unohtumattomia')} </div>
                    <div>{t('lomamuistoja.')} </div>
                  </div>
                </H>
              </Spacing>
              <Spacing bottom={[2, 12, 12]}>
                <H styling="h2" tag="div" data>
                  {t('Aloitetaanko?')}
                </H>
              </Spacing>
            </TypedText>
          </div>
          <Spacing bottom={[7, 13, 13]}>
            <SlideUpAnimation show={showButton}>
              <Button onClick={startChat} data-cy="home-start-search-chat">
                <ButtonText variant="primary" color="white">
                  {t('Joo! Aloitetaan!')}
                </ButtonText>
              </Button>
            </SlideUpAnimation>
          </Spacing>
        </div>
      </Container>
      {/*language=CSS*/}
      <style jsx>{`
        .start-screen,
        .start-screen > :global(div) {
          display: flex;
          width: 100%;
        }

        .flex-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          text-align: center;
          padding-bottom: ${spacing.three};
          width: 100%;
        }

        .tim-and-welcome-text {
          text-align: center;
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
        }

        /* Make sure the typed text container is 100% of the width, because the text is centered */
        :global(.typed-text .visible-text) {
          width: 100%;
        }

        @media ${breakpoints.medium} {
          .tim-text-wrapper > div {
            display: inline;
          }

          .start-screen {
            background-image: linear-gradient(
              to bottom,
              rgba(243, 243, 243, 0) 26%,
              ${color.lightGray} 94%
            );
            background-size: 100% 80px;
            background-position: bottom center;
            background-repeat: no-repeat;
          }
        }
      `}</style>
    </div>
  );
};

const SlideUpAnimation: React.FunctionComponent<{
  show: boolean;
}> = ({ show, children }) => {
  return (
    <TestableSpring
      to={{
        opacity: show ? 1 : 0,
        transform: show ? 'translate3d(0,0,0)' : 'translate3d(0,100%,0)'
      }}
      delay={400}
    >
      {(props) => <div style={props}>{children}</div>}
    </TestableSpring>
  );
};

export default StartScreen;
