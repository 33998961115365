import React from 'react';
import Div100vh from 'react-div-100vh';
import { QuestionCollectionLoadable } from '../../redux/features/questions';
import ModalNavbar from '../common/ModalNavbar';
import RenderAsync from '../common/RenderAsync';
import SearchChat from './SearchChat';

interface SearchChatModalContentProps {
  title: string;
  closeChat: () => void;
  questionCollectionLoadable: QuestionCollectionLoadable;
}

const SearchChatModalContent: React.FunctionComponent<SearchChatModalContentProps> =
  ({ title, closeChat, questionCollectionLoadable }) => {
    return (
      <Div100vh>
        <div className="chat-wrapper">
          <RenderAsync
            state={
              questionCollectionLoadable &&
              questionCollectionLoadable.loadingState
            }
          >
            <SearchChat
              header={<ModalNavbar title={title} closeDialog={closeChat} />}
              questions={questionCollectionLoadable.questions}
            />
          </RenderAsync>
        </div>
        {/*language=CSS*/}
        <style jsx>{`
          .chat-wrapper {
            display: flex;
            flex-direction: column;
            height: 100%;
            flex: 1;
            overflow-y: auto;
          }
        `}</style>
      </Div100vh>
    );
  };

export default SearchChatModalContent;
