import React, { ReactNode, useContext, useState } from 'react';
import { UserContext } from '../../pages/_app';
import Chat, { ChatMessage } from '../chat/Chat';
import { createPool } from '../../utils/pool';
import {
  fantasticReplies,
  startPhrase,
  thankYouReplies,
  yesReplies
} from '../../chat/texts';
import {
  AnswerOption,
  Question,
  QuestionCollection
} from '../../redux/features/questions';
import { Answers } from '../../search/SearchAnswers';
import { trackAnalyticsEvent } from '../../analytics/analytics';
import SearchResultsCard from './SearchResultsCard';

const pickYesReply = createPool(yesReplies);
const pickFantasticReply = createPool(fantasticReplies);
const pickThankYouReply = createPool(thankYouReplies);

function pickRandomReply(replies: string[]) {
  const pickFromPool = createPool(replies);
  return pickFromPool() || '';
}

function createMessage(message: React.ReactNode, isUser = false): ChatMessage {
  return {
    message: <div>{message}</div>,
    isUser
  };
}

function createInitialMessages(questions: Question[]) {
  return [
    createMessage(pickFantasticReply()),
    createMessage(startPhrase),
    createMessage(questions[0].questionText)
  ];
}

function createAnswerReplyMessages(
  questions: Question[],
  option: AnswerOption
) {
  return [
    createMessage(option.label, true),
    createMessage(pickYesReply() + ' ' + pickRandomReply(option.replies))
  ];
}

function createTransitionMessages(nextQuestion: Question | undefined) {
  return nextQuestion
    ? [createMessage(nextQuestion.questionText)]
    : [createMessage(pickThankYouReply())];
}

interface SearchChatProps {
  questions: QuestionCollection;
  header?: ReactNode;
}

const ANALYTICS_CATEGORY = 'Kysymyskone';

const SearchChat: React.FunctionComponent<SearchChatProps> = ({
  questions,
  header
}) => {
  const [questionIndex, setCurrentQuestionIndex] = useState(0);
  const [messages, setMessages] = useState(createInitialMessages(questions));
  const [searchAnswers, setSearchAnswers] = useState<Answers>({});

  const incrementQuestionIndex = () =>
    setCurrentQuestionIndex(questionIndex + 1);

  const { user } = useContext(UserContext);

  const currentQuestion = questions[questionIndex];
  const noQuestionsLeft = !currentQuestion;

  const storeAnswer = (questionKey: string, option: AnswerOption) => {
    searchAnswers[questionKey] = [option.value];
    setSearchAnswers(searchAnswers);
  };
  const moveToNextQuestion = (option: AnswerOption) => {
    const nextQuestion = questions[questionIndex + 1];
    storeAnswer(currentQuestion.key, option);
    setMessages([
      ...messages,
      ...createAnswerReplyMessages(questions, option),
      ...createTransitionMessages(nextQuestion)
    ]);
    incrementQuestionIndex();
    if (!nextQuestion) {
      user.setChatFinished(true);
      trackAnalyticsEvent(
        ANALYTICS_CATEGORY,
        'Vastattu chattiin',
        JSON.stringify(searchAnswers)
      );
    }
  };

  return (
    <Chat
      messages={messages}
      question={
        currentQuestion && {
          options: currentQuestion.options,
          onAnswer: moveToNextQuestion
        }
      }
      header={header}
      customBottomElement={
        noQuestionsLeft && (
          <SearchResultsCard
            searchAnswers={searchAnswers}
            showSuggestionNotificationWhenNoResultsFound
          />
        )
      }
    />
  );
};

export default SearchChat;
