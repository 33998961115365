import React, { ReactNode, useContext } from 'react';
import ContentLoader from 'react-content-loader';
import {
  propToResponsivePropObject,
  ResponsiveProp
} from '../../utils/layoutUtils';
import { WindowContext } from './WindowContextProvider';

const renderBoxSkeleton = (
  uniqueKey: string,
  height: string,
  width = '100%'
): ReactNode => (
  <div style={{ width, height }}>
    <ContentLoader style={{ width, height }} uniqueKey={uniqueKey}>
      <rect x="0" y="0" rx="8" ry="8" width="100%" height="100%" />
    </ContentLoader>
  </div>
);

interface BoxSkeletonProps {
  uniqueKey: string;
  height: ResponsiveProp<string>;
  width?: ResponsiveProp<string>;
}
export const BoxSkeleton: React.FunctionComponent<BoxSkeletonProps> = ({
  uniqueKey,
  height,
  width = '100%'
}) => {
  const { breakpoint } = useContext(WindowContext);
  const widths = propToResponsivePropObject(width);
  const heights = propToResponsivePropObject(height);
  const breakpointHeight = breakpoint && heights[breakpoint];
  const breakpointWidth = breakpoint && widths[breakpoint];

  if (breakpoint && breakpointHeight) {
    return (
      <>{renderBoxSkeleton(uniqueKey, breakpointHeight, breakpointWidth)}</>
    );
  }

  return null;
};
