import cloneDeep from 'lodash/cloneDeep';
import { CmsMemory } from '../integrations/contentful/types';
import { Question } from '../redux/features/questions';
import { Answers } from './SearchAnswers';

export function filterMemories(memories: CmsMemory[], searchAnswers: Answers) {
  const searchAnswerKeys = Object.keys(searchAnswers);

  if (searchAnswerKeys.length === 0) {
    return memories.slice();
  }

  return memories.filter((memory) => {
    return searchAnswerKeys.every((key) => {
      const firstAnswer = searchAnswers[key][0];
      // @ts-ignore
      const memoryAnswers = memory.fields[key];

      if (!Array.isArray(memoryAnswers) || memoryAnswers.length === 0) {
        return false;
      }

      return memoryAnswers.indexOf(firstAnswer) !== -1;
    });
  });
}

export function removeSearchAnswersForOptionalQuestions(
  searchAnswers: Answers,
  questions: Question[]
): Answers {
  const optionalQuestions = questions.filter((question) => !question.mandatory);
  const answers = cloneDeep(searchAnswers);
  optionalQuestions.forEach(function (optionalQuestions) {
    answers[optionalQuestions.key] = [];
  });
  return answers;
}
