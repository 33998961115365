import React, { ReactNode } from 'react';
import * as ReactDOM from 'react-dom';
import { breakpoints, color, spacing } from '../../styles/theme';
import Spacing from '../common/Spacing';
import Button from '../elements/Button';
import ButtonText from '../elements/ButtonText';
import DelayRender from '../common/DelayRender';
import { AnswerOption } from '../../redux/features/questions';
import ControlledFadeAnimation from '../animations/ControlledFadeAnimation';
import UserMessage from './UserMessage';
import AnimatedTimMessage from './../tim/AnimatedTimMessage';
import AnswerOptions from './AnswerOptions';

const bottomSafePadding = spacing.two;

export interface ChatMessage {
  message: React.ReactNode;
  isUser: boolean;
}

export interface ChatQuestion {
  options: AnswerOption[];
  onAnswer: (option: AnswerOption) => void;
}

interface ChatProps {
  messages: ChatMessage[];
  question?: ChatQuestion;
  header?: ReactNode;
  customBottomElement?: ReactNode;
}

interface ChatState {
  currentMessageIndex: number;
}

class Chat extends React.Component<ChatProps> {
  public state: ChatState = {
    currentMessageIndex: 0
  };
  private scrollWrapperRef = React.createRef<HTMLDivElement>();

  public maybeScrollToBottom = () => {
    if (!this.scrollWrapperRef.current) {
      return;
    }

    const node = ReactDOM.findDOMNode(
      this.scrollWrapperRef.current
    ) as HTMLElement;
    const newScrollPosition = node.scrollHeight - node.clientHeight;
    if (node.scrollTop !== newScrollPosition) {
      node.scrollTop = node.scrollHeight - node.clientHeight;
    }
  };

  public scrollToBottom = () => {
    window.requestAnimationFrame(this.maybeScrollToBottom);
  };

  public incrementMessageIndex = () => {
    this.setState((state: ChatState) => ({
      currentMessageIndex: state.currentMessageIndex + 1
    }));
  };

  public moveForward = () => {
    this.incrementMessageIndex();
  };

  public renderMessage = (
    message: ChatMessage,
    index: number,
    waiting: boolean
  ) => {
    return (
      <div key={index}>
        {message.isUser
          ? this.renderUserMessage(message, index)
          : this.renderTimMessage(message, index, waiting)}
      </div>
    );
  };

  public renderUserMessage = (message: ChatMessage, index: number) => {
    return (
      <Spacing bottom={4}>
        <div className="user-message" data-cy="chat-user-message">
          <UserMessage
            onAnimationDone={() => {
              // We call this only when the message is rendered for the first time,
              // because otherwise it is triggered on every render and causes multiple re-renders
              if (index === this.state.currentMessageIndex) {
                this.moveForward();
                this.scrollToBottom();
              }
            }}
          >
            {message.message}
          </UserMessage>
          {/*language=CSS*/}
          <style jsx>{`
            .user-message {
              display: flex;
              justify-content: flex-end;
            }
          `}</style>
        </div>
      </Spacing>
    );
  };

  public renderTimMessage = (
    message: ChatMessage,
    index: number,
    waiting: boolean
  ) => {
    const previousMessage = this.props.messages[index - 1];
    const previousMessageFromTim = previousMessage && !previousMessage.isUser;
    const showIcon = !previousMessageFromTim;
    return (
      <DelayRender duration={400} onVisible={this.scrollToBottom}>
        <Spacing bottom={2}>
          <div data-cy="chat-tim-message">
            <AnimatedTimMessage
              onAnimationDone={this.moveForward}
              showIcon={showIcon}
              iconActive={showIcon && this.isIconActive(index)}
              talking={!waiting}
            >
              {message.message}
            </AnimatedTimMessage>
          </div>
        </Spacing>
      </DelayRender>
    );
  };

  public isIconActive = (messageIndex: number) => {
    const nextMessages = this.props.messages.slice(messageIndex);
    const userMessageInFront = nextMessages.some((message) => {
      return message.isUser;
    });
    // Icon should be active when there are no user messages in front
    return !userMessageInFront;
  };

  public renderAnsweringOption = (option: AnswerOption, index: number) => {
    const { question } = this.props;
    if (!question) {
      return null;
    }
    const isLastItem = index + 1 === question.options.length;
    return (
      <Spacing bottom={isLastItem ? 0 : 2} key={option.value}>
        <Button
          onClick={() => question.onAnswer(option)}
          data-cy="chat-answer-option-button"
        >
          <ButtonText variant="primary" color="white">
            {option.label}
          </ButtonText>
        </Button>
      </Spacing>
    );
  };

  public render() {
    const { messages, question, header, customBottomElement } = this.props;
    const { currentMessageIndex } = this.state;
    const messagesAnimated = currentMessageIndex >= messages.length;
    const messagesToRender = messages.slice(0, currentMessageIndex + 1);
    const showCustomBottomElement =
      !!customBottomElement && messagesAnimated && !question;

    return (
      <div className="scroll-wrapper" ref={this.scrollWrapperRef}>
        {header}
        <div className="chat">
          <div className="chat-messages" data-testid="chat-messages">
            {messagesToRender.map((message, index) =>
              this.renderMessage(message, index, messagesAnimated)
            )}
          </div>
          <div className="bottom-area">
            <div className="bottom-content">
              <ControlledFadeAnimation
                show={messagesAnimated}
                onAnimate={this.scrollToBottom}
              >
                {messagesAnimated && question && (
                  <AnswerOptions question={question} />
                )}
              </ControlledFadeAnimation>
              <ControlledFadeAnimation
                show={showCustomBottomElement}
                onAnimate={this.scrollToBottom}
                delay={400}
              >
                {customBottomElement}
              </ControlledFadeAnimation>
            </div>
          </div>
        </div>
        {/*language=CSS*/}
        <style jsx>{`
          .scroll-wrapper {
            overflow: auto;
            display: flex;
            flex-direction: column;
            height: 100%;
            background: ${color.white};
          }
          .chat {
            display: flex;
            flex: 1 0 auto;
            flex-direction: column;
            justify-content: space-between;
            padding: ${spacing.three} ${spacing.two};
          }
          .chat-messages {
            flex-shrink: 0;
          }
          .bottom-area {
            flex-shrink: 0;
            overflow: hidden;
            /* Overflow: hidden cuts box shadow of answering option buttons and search results card.
               We add padding for bottom-content to have enough space for box shadows.
               Re-align bottom content back in place using negative margin in parent element. */
            margin: -${bottomSafePadding};
          }
          .bottom-content {
            /* Add padding so that have enough space for box shadows */
            padding: ${bottomSafePadding};
          }

          @media ${breakpoints.medium} {
            .chat {
              padding-left: ${spacing.four};
              padding-right: ${spacing.four};
            }
          }
        `}</style>
      </div>
    );
  }
}

export default Chat;
