import React, { useEffect, useState } from 'react';
import { AnimationSegment } from 'lottie-web';
import classNames from 'classnames';
import { baseXInPixels, breakpoints } from '../../styles/theme';
import Lottie, { OnSegmentStartEvent } from '../animations/Lottie';
import { usePrevious } from '../../hooks/usePrevious';
import {
  propToResponsivePropObject,
  ResponsiveProp
} from '../../utils/layoutUtils';

export type TimState = 'talk' | 'inactive' | 'idle' | 'reveal';
type TimStateTransition = 'hide';

interface AnimationOptions {
  segment: AnimationSegment;
  startTransitionSegment?: AnimationSegment;
  endTransitionSegment?: AnimationSegment;
}

function createAnimationOptions(
  segment: AnimationSegment,
  startTransitionSegment?: AnimationSegment,
  endTransitionSegment?: AnimationSegment
) {
  return {
    segment,
    startTransitionSegment,
    endTransitionSegment
  };
}

const stateAnimationMap: Record<
  TimState | TimStateTransition,
  AnimationOptions
> = {
  /*
   * /static/animations/tim.json segment (60fps) frame ranges are as following:
   * Reveal:  	  00000-00013
   * Activate: 	  00053-00066
   * Talk:		  00100-00186 (This is a single loop, the whole animation is frames 86-0186)
   * Deactivate:  00206-00219
   * End:		  00250-00290
   * Start:		  00330-00343
   * Pause:		  00380-00620
   * Hide:		  00700-00713
   */
  reveal: createAnimationOptions([14, 15], [0, 13]),
  talk: createAnimationOptions([100, 108], [53, 86], [206, 219]),
  inactive: createAnimationOptions([309, 310], [250, 290], [330, 343]),
  idle: createAnimationOptions([380, 620]),
  hide: createAnimationOptions([700, 713])
};

// The container of the Tim animation is bigger than that dot itself.
// This semi-random multiple is the ratio between the container and the dot.
const timBaseXMultiple = 133 / 24;
const Tim: React.FunctionComponent<{
  animateIn?: boolean;
  state: TimState;
  onAnimationDone?: () => void;
  onReveal?: () => void;
  size?: ResponsiveProp<number>;
}> = ({ animateIn, state, onAnimationDone, onReveal, size = 3 }) => {
  const [segments, setSegments] = useState<AnimationSegment[]>([]);
  const [hasBeenRevealed, setHasBeenRevealed] = useState<boolean>(false);
  const prevState: TimState | undefined = usePrevious(state);
  const sizeValues = propToResponsivePropObject(size).mapDefined(
    (value: number) => Math.ceil(baseXInPixels(value)) + 'px'
  );
  const timValues = propToResponsivePropObject(size).mapDefined(
    (value: number) =>
      Math.round(baseXInPixels(timBaseXMultiple * value)) + 'px'
  );

  useEffect(() => {
    if (prevState === state) {
      return;
    }
    const newSegments: AnimationSegment[] = [];

    if (
      animateIn &&
      segments.length === 0 &&
      state !== 'reveal' &&
      stateAnimationMap.reveal.startTransitionSegment
    ) {
      newSegments.push(stateAnimationMap.reveal.startTransitionSegment);
    }

    const prevAnimationOptions = prevState && stateAnimationMap[prevState];
    if (
      prevAnimationOptions &&
      // @ts-ignore -- TypeScript thinks this is 'never', which is not true
      prevAnimationOptions.endTransitionSegment
    ) {
      newSegments.push(
        // @ts-ignore
        prevAnimationOptions.endTransitionSegment
      );
    }

    const skipStartTransition = !animateIn && !prevState;
    const currentAnimationOptions = stateAnimationMap[state];
    if (
      !skipStartTransition &&
      currentAnimationOptions.startTransitionSegment
    ) {
      newSegments.push(currentAnimationOptions.startTransitionSegment);
    }

    newSegments.push(stateAnimationMap[state].segment);

    setSegments(newSegments);
  }, [animateIn, prevState, state, segments]);

  const onSegmentStart = ({ firstFrame }: OnSegmentStartEvent) => {
    const revealLoopStartFrame = stateAnimationMap.reveal.segment[0];
    if (firstFrame === revealLoopStartFrame && !hasBeenRevealed) {
      if (onReveal) {
        onReveal();
      }
      setHasBeenRevealed(true);
    }
  };

  return (
    <div className={classNames('tim', state)} data-cy="tim">
      <div className="animation-wrapper">
        {segments && (
          <Lottie
            animationPath="/static/animations/tim.json"
            loop
            segments={segments}
            onComplete={onAnimationDone}
            onSegmentStart={onSegmentStart}
          />
        )}
      </div>
      {/*language=CSS*/}
      <style jsx data-testid="tim-styles">{`
        .tim {
          width: ${sizeValues.small};
          height: ${sizeValues.small};
          position: relative;
        }
        .animation-wrapper {
          width: ${timValues.small};
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate3d(-50%, -50%, 0);
        }

        @media ${breakpoints.medium} {
          .tim {
            width: ${sizeValues.medium};
            height: ${sizeValues.medium};
          }
          .animation-wrapper {
            width: ${timValues.medium};
          }
        }

        @media ${breakpoints.large} {
          .tim {
            width: ${sizeValues.large};
            height: ${sizeValues.large};
          }
          .animation-wrapper {
            width: ${timValues.large};
          }
        }
      `}</style>
    </div>
  );
};

export default Tim;
