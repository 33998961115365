import React from 'react';
import { TestableSpring } from '../module-overrides/react-spring';

interface FadeAnimationProps {
  // You can control when animation is triggered with the show prop
  show: boolean;
  onAnimate?: () => void;
  delay?: number;
}

const ControlledFadeAnimation: React.FunctionComponent<FadeAnimationProps> = ({
  show,
  onAnimate,
  children,
  delay
}) => {
  return (
    <TestableSpring
      to={{
        opacity: show ? 1 : 0,
        height: show ? 'auto' : 0
      }}
      delay={delay}
      onFrame={onAnimate}
    >
      {(props) => <div style={props}>{children}</div>}
    </TestableSpring>
  );
};

export default ControlledFadeAnimation;
