import { t } from '../utils/i18n';

export const startPhrase = t('Lähdetään liikkeelle muutamalla kysymyksellä 👇');

export const fantasticReplies = [
  t('Mainiota! 😄'),
  t('Très bien! 🍷'),
  t('Fantastic! 💂'),
  t('Mahtavaa! 🤗'),
  t('Muy bien! 💃')
];
export const yesReplies = [
  t('Erinomaista!'),
  t('Kuulostaa hyvältä!'),
  t('Jes!'),
  t('OK!'),
  t('Hyvä!'),
  t('Hienoa!')
];
export const thankYouReplies = [
  t('Kiitos!'),
  t('Grazie mille!'),
  t('Danke schön!'),
  t('Merci beaucoup!'),
  t('Huh! Maalissa!'),
  t('Kiitti!'),
  t('Valmista tuli!')
];
