import React from 'react';
import { baseXUnit, breakpoints } from '../../styles/theme';
import H from '../elements/H';
import TypedText from '../common/TypedText';
import { propToResponsivePropObject } from '../../utils/layoutUtils';
import Tim, { TimState } from './Tim';

interface AnimatedTimMessageProps {
  onAnimationDone?: () => void;
  showIcon?: boolean;
  iconActive?: boolean;
  disableAnimation?: boolean;
  talking?: boolean;
}

const iconSizesBase = [2, 2, 3];
const iconSizes =
  propToResponsivePropObject(iconSizesBase).mapDefined(baseXUnit);
const AnimatedTimMessage: React.FunctionComponent<AnimatedTimMessageProps> = ({
  children,
  onAnimationDone,
  showIcon,
  iconActive,
  disableAnimation,
  talking
}) => {
  let timState: TimState = 'inactive';
  if (iconActive && talking) {
    timState = 'talk';
  } else if (iconActive && !talking) {
    timState = 'idle';
  }

  return (
    <div className="root">
      <div className="icon-wrapper" data-testid="icon-wrapper">
        {showIcon && <Tim state={timState} size={iconSizesBase} />}
      </div>
      <div className="message-wrapper">
        <H styling="h4" data tag="div">
          <TypedText
            startDelay={showIcon ? 200 : 0}
            onAnimationDone={onAnimationDone}
            disableAnimation={disableAnimation}
          >
            {children}
          </TypedText>
        </H>
      </div>
      {/*language=CSS*/}
      <style jsx>{`
        .root {
          display: flex;
        }
        .icon-wrapper {
          flex-shrink: 0;
          width: ${iconSizes.small};
          margin-right: ${baseXUnit(2)};
          margin-bottom: -${iconSizes.small};
        }
        @media ${breakpoints.medium} {
          .icon-wrapper {
            width: ${iconSizes.medium};
            margin-bottom: -${iconSizes.medium};
          }
        }

        @media ${breakpoints.large} {
          .icon-wrapper {
            width: ${iconSizes.large};
            margin-bottom: -${iconSizes.large};
          }
        }
      `}</style>
    </div>
  );
};

export default AnimatedTimMessage;
