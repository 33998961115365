import React from 'react';
import { animated } from 'react-spring';
import H from '../elements/H';
import { useTestableSpring } from '../module-overrides/react-spring';
import { baseXUnit, color } from '../../styles/theme';

interface UserMessageProps {
  onAnimationDone?: () => void;
}

const UserMessage: React.FunctionComponent<UserMessageProps> = ({
  children,
  onAnimationDone
}) => {
  const props = useTestableSpring({
    onRest: onAnimationDone,
    delay: 0,
    from: { opacity: 0, transform: 'translate3d(0,100%,0)' },
    to: { opacity: 1, transform: 'translate3d(0,0,0,)' }
  });
  return (
    <animated.div style={props}>
      <div className="message">
        <H styling="h4" color="white">
          <strong>{children}</strong>
        </H>
      </div>
      {/*language=CSS*/}
      <style jsx>{`
        .message {
          border-radius: ${baseXUnit(1)} ${baseXUnit(1)} 0 ${baseXUnit(1)};
          background: ${color.purple};
          padding: ${baseXUnit(1)} ${baseXUnit(1)};
        }
      `}</style>
    </animated.div>
  );
};

export default UserMessage;
