import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { color } from '../../styles/theme';
import H from '../elements/H';
import { QuestionCollection } from '../../redux/features/questions';
import { t } from '../../utils/i18n';
import { Answers, getAnswerOptionLabels } from '../../search/SearchAnswers';

interface SearchResultsCardTextProps {
  questions: QuestionCollection;
  searchAnswers: Answers;
  // Show special text to tell user his/her search answers were modified.
  suggestion?: boolean;
}

const SearchResultsCardText: React.FunctionComponent<SearchResultsCardTextProps> =
  ({ questions, searchAnswers, suggestion = false }) => {
    const answerOptionLabels = getAnswerOptionLabels(questions, searchAnswers);
    const searchAnswersCount = Object.keys(searchAnswers).filter((key) => {
      const searchAnswer = searchAnswers[key];
      return !isEmpty(searchAnswer);
    }).length;
    const allQuestionsAnswered = searchAnswersCount === questions.length;
    const allQuestionsAnsweredOrSuggestion = allQuestionsAnswered || suggestion;
    return (
      <H styling="h4" data data-cy="search-results-card-answers" tag="div">
        {getIntroductionText(suggestion, allQuestionsAnswered)}
        {allQuestionsAnsweredOrSuggestion &&
          answerOptionLabels.map(
            (answerOptionLabel, index, answerOptionLabels) => {
              const lastItem = index === answerOptionLabels.length - 1;
              return (
                <span key={answerOptionLabel}>
                  <span className={'answer'}>
                    {/* Italic style loading causes strange 1px transition during loading. Does not really matter here much. */}
                    <i>{answerOptionLabel.toLowerCase()}</i> {lastItem && '🎉'}
                  </span>
                </span>
              );
            }
          )}
        {/*language=CSS*/}
        <style jsx>{`
          .answer {
            color: ${color.purple};
          }
        `}</style>
      </H>
    );
  };

function getIntroductionText(
  suggestion: boolean,
  allQuestionsAnswered: boolean
) {
  if (suggestion) {
    return (
      t(
        'En löytänytkään matkoja juuri noilla toiveilla… Mutta onneksi löysin matkoja joilla voit'
      ) + ' '
    );
  }
  if (allQuestionsAnswered) {
    return t('Löysin sinulle matkoja joilla voit') + ' ';
  }

  return t('Löysin sinulle matkoja joista saattaisit tykätä 🎉');
}

export default SearchResultsCardText;
