import React, { useEffect, useRef, useState } from 'react';
import { configuration } from '../../utils/environment';

interface DelayRenderProps {
  duration: number;
  onVisible?: () => void;
}

const DelayRender: React.FunctionComponent<DelayRenderProps> = ({
  onVisible,
  duration,
  children
}) => {
  const [visible, setVisible] = useState(false);
  const ref = useRef(null);

  if (configuration.disableAnimations) {
    duration = 0;
  }

  useEffect(() => {
    setTimeout(() => {
      const componentIsStillMounted = !!ref.current;
      if (componentIsStillMounted) {
        setVisible(true);
      }
    }, duration);
  }, [duration]);
  useEffect(() => {
    if (visible && onVisible) {
      onVisible();
    }
  }, [visible, onVisible]);

  return <div ref={ref}>{visible && children}</div>;
};

export default DelayRender;
