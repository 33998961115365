import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import {
  baseXInPixels,
  baseXUnit,
  breakpoints,
  color,
  spacing
} from '../../styles/theme';
import Spacing from '../common/Spacing';
import CmsMedia from '../cms/CmsMedia';
import { Paths } from '../../urls/Paths';
import RouteLink from '../navigation/RouteLink';
import {
  questionsActions,
  selectSearchChatQuestionCollection
} from '../../redux/features/questions';
import {
  searchResultActions,
  selectSearchResults
} from '../../redux/features/searchResults';
import { Answers } from '../../search/SearchAnswers';
import RenderAsync from '../../components/common/RenderAsync';
import { RootState } from '../../redux/features';
import { BoxSkeleton } from '../common/skeletons';
import HyperLink from '../elements/HyperLink';
import { LoadingState } from '../../loading/loadable';
import { removeSearchAnswersForOptionalQuestions } from '../../search/searchUtils';
import CallToActionBottomBox from '../common/CallToActionBottomBox';
import { t } from '../../utils/i18n';
import SearchResultsCardText from './SearchResultsCardText';

const imageHeightBase = 5;
const imageWidthBase = 7;
const imageCount = 4;

interface SearchResultsCardProps {
  searchAnswers: Answers;
  // We have logic to always show memories to user - even in the cases where we can't find memories with the exact search answers.
  // In some cases we want notify user his/her search answers were modified.
  // This variable controls if we show special text or not.
  showSuggestionNotificationWhenNoResultsFound: boolean;
}

const SearchResultsCard: React.FunctionComponent<SearchResultsCardProps> = ({
  searchAnswers: initialSearchAnswers,
  showSuggestionNotificationWhenNoResultsFound:
    showSuggestionTextWhenNoResultsFound
}) => {
  const [searchAnswers, setSearchAnswers] =
    React.useState<Answers>(initialSearchAnswers);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(questionsActions.makeSureSearchChatQuestionsLoaded());
    dispatch(
      searchResultActions.fetchSearchResultsIfNotFetched({
        searchAnswers: searchAnswers
      })
    );
  }, [searchAnswers, dispatch]);
  // When we have loaded memories using only some search answers, because we did not find
  // memories match to all search answers.
  const [suggestionUsed, setSuggestionUsed] = React.useState<boolean>(false);
  const questionCollectionLoadable = useSelector(
    selectSearchChatQuestionCollection
  );
  const searchResultsLoadable = useSelector((state: RootState) =>
    selectSearchResults(state, searchAnswers)
  );
  const skeletonLoaderHeight = baseXInPixels(27) + 1 + 'px'; // 1px border

  const noMemories =
    searchResultsLoadable &&
    searchResultsLoadable.results &&
    searchResultsLoadable.results.memories &&
    searchResultsLoadable.results.memories.length === 0;
  const finishedLoadingAndNoMemories =
    searchResultsLoadable &&
    searchResultsLoadable.loadingState === LoadingState.DONE &&
    noMemories;

  if (finishedLoadingAndNoMemories && !suggestionUsed) {
    // We always want show some memories for user.
    // Should always have some memories when using only mandatory search answers.
    const searchAnswersWithOnlyMandatoryAnswers =
      removeSearchAnswersForOptionalQuestions(
        searchAnswers,
        questionCollectionLoadable.questions
      );
    setSearchAnswers(searchAnswersWithOnlyMandatoryAnswers);
    setSuggestionUsed(true);
    dispatch(
      searchResultActions.fetchSearchResultsIfNotFetched({
        searchAnswers: searchAnswers
      })
    );
  }

  return (
    <div className="search-results-card" data-cy="search-results-card">
      <RouteLink path={Paths.SearchResults} query={searchAnswers}>
        <HyperLink noLinkStateStyles>
          <RenderAsync
            state={
              questionCollectionLoadable &&
              questionCollectionLoadable.loadingState &&
              searchResultsLoadable &&
              searchResultsLoadable.loadingState
            }
            onLoading={() => {
              return (
                <BoxSkeleton
                  uniqueKey="search-results-card-skeleton"
                  height={skeletonLoaderHeight}
                />
              );
            }}
          >
            {() => {
              return (
                <div className="container">
                  <div className="top">
                    <SearchResultsCardText
                      questions={questionCollectionLoadable.questions}
                      searchAnswers={searchAnswers}
                      suggestion={
                        showSuggestionTextWhenNoResultsFound && suggestionUsed
                      }
                    />
                    <Spacing top={3} bottom={2}>
                      <div className="images">
                        {searchResultsLoadable.results.memories
                          .slice(0, imageCount)
                          .map((memory, index, visibleMemories) => {
                            const lastItem =
                              index === visibleMemories.length - 1;
                            return (
                              <div
                                key={index}
                                className="image-container"
                                data-testid="image-container"
                              >
                                <div
                                  className={classnames('image', {
                                    last: lastItem
                                  })}
                                >
                                  {memory.fields.mainImage && (
                                    <CmsMedia
                                      media={memory.fields.mainImage}
                                      width={baseXInPixels(imageWidthBase)}
                                      height={baseXInPixels(imageHeightBase)}
                                      borderRadius={1}
                                      crop
                                    />
                                  )}
                                </div>
                                {lastItem && (
                                  <div
                                    className="read-more-dots"
                                    data-testid="read-more-dots"
                                  >
                                    •••
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </Spacing>
                  </div>
                  <div className="line" />
                  <div className="bottom">
                    <Spacing top={2}>
                      <CallToActionBottomBox
                        primaryText={t('Näytä matkat')}
                        secondaryText={t('TIM SUOSITTELEE')}
                        icon="nav-arrow-right"
                      />
                    </Spacing>
                  </div>
                </div>
              );
            }}
          </RenderAsync>
        </HyperLink>
      </RouteLink>
      {/*language=CSS*/}
      <style jsx>{`
        .container {
          background: #fff;
          border-radius: ${baseXUnit(1)};
          box-shadow: 0 2px ${baseXUnit(2)} 0 rgba(0, 0, 0, 0.15);
          padding-top: ${spacing.two};
          padding-bottom: ${spacing.two};
          transition: 0.5s;
        }
        .container:hover {
          box-shadow: 0 2px ${baseXUnit(2)} 0 rgba(0, 0, 0, 0.2);
        }
        .top,
        .bottom {
          margin: 0 ${spacing.two};
        }
        .images {
          display: flex;
          flex-wrap: wrap;
          /* Image loading should not change element height. Add min height and some safe margin to prevent this on mobile. 
          Element will start bouncing on loading when image is bigger than imageHeightBase + 1. This happens desktop or very wide mobile devices. */
          min-height: ${baseXUnit(imageHeightBase + 1)};
        }
        .image-container {
          position: relative;
          width: ${baseXUnit(7)};
          /* leave at least 20% - margin space to right */
          max-width: ${80 / imageCount}%;
          margin-right: ${spacing.one};
        }
        .read-more-dots {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: ${color.gray};
          padding: inherit;
        }
        .image.last {
          opacity: 0.3;
          background-color: ${color.white};
        }
        .line {
          border-bottom: 1px dashed ${color.lightGray};
        }

        @media ${breakpoints.medium} {
          .container {
            padding-top: ${baseXUnit(2.5)};
            padding-bottom: ${baseXUnit(1.5)};
          }
        }
      `}</style>
    </div>
  );
};

export default SearchResultsCard;
