import React from 'react';
import { spacing } from '../../styles/theme';
import ButtonText from '../elements/ButtonText';
import H from '../elements/H';
import Icon, { IconName } from './Icon';

interface CallToActionBottomBox {
  primaryText: string;
  secondaryText?: string;
  icon: IconName;
}

const CallToActionBottomBox: React.FunctionComponent<CallToActionBottomBox> = ({
  primaryText,
  secondaryText,
  icon,
  ...rest
}) => {
  return (
    <div className="call-to-action-bottom-box" {...rest}>
      <div className="text-container">
        <ButtonText variant="primary">{primaryText}</ButtonText>
        {secondaryText && (
          <H styling="h5" color="gray">
            <div className="secondary-text">{secondaryText}</div>
          </H>
        )}
      </div>
      <div className="icon-container">
        <Icon name={icon} size={4} color="purple-light" />
      </div>
      {/*language=CSS*/}
      <style jsx>{`
        .call-to-action-bottom-box {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .secondary-text {
          line-height: ${spacing.one};
        }
      `}</style>
    </div>
  );
};

export default CallToActionBottomBox;
