import React from 'react';
import Spacing from '../common/Spacing';
import Button from '../elements/Button';
import ButtonText from '../elements/ButtonText';
import { AnswerOption } from '../../redux/features/questions';
import { ChatQuestion } from './Chat';

interface AnswerOptionsProps {
  question: ChatQuestion;
  selectedAnswerOption?: AnswerOption;
}

const AnswerOptions: React.FunctionComponent<AnswerOptionsProps> = ({
  question,
  selectedAnswerOption
}) => {
  return (
    <div className="answer-options">
      {question.options.map((option) => (
        <Spacing bottom={2} key={option.value}>
          <Button
            onClick={() => question.onAnswer(option)}
            data-cy="chat-answer-option-button"
            data-testid="chat-answer-option-button"
            selected={option === selectedAnswerOption}
          >
            <ButtonText variant="primary" color="white">
              {option.label}
            </ButtonText>
          </Button>
        </Spacing>
      ))}
      {/*language=CSS*/}
      <style jsx>{`
        .answer-options {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: flex-end;
          position: relative;
        }
      `}</style>
    </div>
  );
};

export default AnswerOptions;
