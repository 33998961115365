import React, { useContext } from 'react';
import { t } from '../../utils/i18n';
import H from '../elements/H';
import Spacing from '../common/Spacing';
import Tim, { TimState } from '../tim/Tim';
import Container from '../grid/Container';
import ButtonText from '../elements/ButtonText';
import SearchResultsCard from '../search/SearchResultsCard';
import IconText from '../common/IconText';
import DivButton from '../a11y/DivButton';
import { TestableSpring } from '../module-overrides/react-spring';
import TypedText from '../common/TypedText';
import { SearchContext } from '../../pages/_app';
import { breakpoints, color } from '../../styles/theme';
import Row from '../grid/Row';
import Col from '../grid/Col';
import HyperLink from '../elements/HyperLink';

interface WelcomeBackScreenProps {
  startChat: () => void;
}

const WelcomeBackScreen: React.FunctionComponent<WelcomeBackScreenProps> = ({
  startChat
}) => {
  const searchContext = useContext(SearchContext);
  const searchAnswers = searchContext.answers.getAll();
  const [timState, setTimState] = React.useState<TimState>('reveal');
  const showText = timState !== 'reveal';
  const searchResultsCardAndButtonVisible = timState === 'idle';

  return (
    <div className="welcome-back-screen" data-cy="welcome-back-screen">
      <Container>
        <div className="flex-wrapper">
          <div>
            <div className="tim-and-welcome-text">
              <Spacing top={[6.5, 8, 8]} bottom={[6.5, 8, 8]}>
                <Tim
                  animateIn
                  state={timState}
                  onReveal={() => {
                    setTimeout(
                      () => setTimState('talk'),
                      // This is set based on how the animations look together
                      700
                    );
                  }}
                />
              </Spacing>
              <H styling="h2" tag="div" data>
                <TypedText
                  wait={!showText}
                  // This is set based on how the animations look together
                  startDelay={700}
                  onAnimationDone={() => setTimState('idle')}
                >
                  <div className="tim-text-wrapper">
                    <div>{t('Moi taas! Viime')} </div>
                    <div>{t('kerralla juteltiinkin')} </div>
                    <div>{t('ja silloin…')} </div>
                  </div>
                </TypedText>
              </H>
            </div>
            <Spacing top={[6, 8, 8]} bottom={[2, 6, 6]}>
              <Row>
                <Col size={[0, 6, 8]} />
                <Col size={[24, 12, 8]}>
                  <SlideUpAnimation show={searchResultsCardAndButtonVisible}>
                    <div
                      className="search-results"
                      data-testid="search-results"
                    >
                      <SearchResultsCard
                        searchAnswers={searchAnswers}
                        showSuggestionNotificationWhenNoResultsFound={false}
                      />
                    </div>
                  </SlideUpAnimation>
                </Col>
                <Col size={[0, 6, 8]} />
              </Row>
            </Spacing>
          </div>
          <SlideUpAnimation show={searchResultsCardAndButtonVisible}>
            <Spacing bottom={[9, 13, 13]}>
              <div className="action">
                <IconText
                  icon="nav-return"
                  iconColor="purple-light"
                  iconSize={3}
                >
                  <DivButton onClick={startChat}>
                    <HyperLink>
                      <ButtonText variant="secondary" color="dark">
                        {t('Haluan vastata kysymyksiin uudelleen')}
                      </ButtonText>
                    </HyperLink>
                  </DivButton>
                </IconText>
              </div>
            </Spacing>
          </SlideUpAnimation>
        </div>
      </Container>
      {/*language=CSS*/}
      <style jsx>{`
        .welcome-back-screen,
        .welcome-back-screen > :global(div) {
          display: flex;
          width: 100%;
        }
        .flex-wrapper {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          width: 100%;
        }
        .tim-and-welcome-text {
          text-align: center;
        }
        .tim-and-welcome-text,
        .action {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        /* Make sure the typed text container is 100% of the width, because the text is centered */
        :global(.typed-text .visible-text) {
          width: 100%;
        }

        @media ${breakpoints.medium} {
          .tim-text-wrapper > div {
            display: inline;
          }

          .welcome-back-screen {
            background-image: linear-gradient(
              to bottom,
              rgba(243, 243, 243, 0) 26%,
              ${color.lightGray} 94%
            );
            background-size: 100% 80px;
            background-position: bottom center;
            background-repeat: no-repeat;
          }
        }
      `}</style>
    </div>
  );
};

const SlideUpAnimation: React.FunctionComponent<{
  show: boolean;
}> = ({ show, children }) => {
  return (
    <TestableSpring
      to={{
        opacity: show ? 1 : 0,
        transform: show ? 'translate3d(0,0,0)' : 'translate3d(0,100%,0)'
      }}
      delay={400}
    >
      {(props) => <div style={props}>{children}</div>}
    </TestableSpring>
  );
};

export default WelcomeBackScreen;
